/*Intro styles*/

.Intro{
    width: 80%;
    display:  flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 0 auto;
    padding-top: 20px;
    text-align: center;
}
.Intro div h3{
    font-size: 2.0rem;
    font-weight: 600;
    margin-bottom: 10px;
}
.Intro .div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.Intro p{
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 10px;
}
.IntroImage{
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
}
.IntroImage img{
    width: 100%;
    max-width: 300px;
}
.Notification{
    display: block;
    position: absolute;
    top: 10%;
    left:90%;
    width: 2rem;
    min-width: 1rem;
    height: 2rem;
    min-height: 1rem;
    color:white;
    cursor: pointer;
}

@media screen and (max-width: 468px) {
.Intro div h3 {
        font-size: 2.0rem;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .Intro .div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}