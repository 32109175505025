/*Search styles*/

.Search{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.Search input{
    width: 50%;
    height: 50px;
    border: none;
    border-radius: 25px;
    padding: 0 20px;
    font-size: 1.2rem;
    font-weight: 600;
    color: #1F4490;
    border: 2px solid rgba(0,0,0,0.5);
}

@media screen and (max-width:469px){
    .Search input{
        width: 90%;
    }
}