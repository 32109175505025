.Box{
    display: flex;
    flex-direction: row;
    width: 100%;
    max-height: 10vh;
    margin-top: 1rem;
    margin-left: 1rem;
    gap: 6;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.5rem;
    /* background-color: red; */
}

/* .ImageContainer{
    width: 30%;
} */

.ImageContainer{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 1rem;
}
.ImageContainer img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}
.MessageContainer{
    width: 80%;
    margin-left: .5rem;
}
.Username{
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
}

.Message{
    font-size: 10px;
    font-weight: 400;
}

@media screen and (max-width: 468px) {
.Box {
    width: 100%;
    padding: .5rem;
    justify-content: flex-start;
}
.Image{
    width: 40px;
    height: 43px;
}
}