.Container{
    width: 90%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(14, 14, 15, 0.1);
    padding: .5rem;
    margin: auto;
}

.Imageandname, .Timeandnumber{
    width: 50%;
}

.Name{
    line-height: .8rem;
}

.Timeandnumber{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
}

.Imageandname{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Image>img {
    width: 60px;
    height: 60px;
    margin-right: .5rem;
    border-radius: 50%;
}

.Number{
    background: #E10000;
    color: #fff;
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    padding: .3rem;
    font-size: 10px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -.05rem;
}

@media screen and (max-width: 468px) {
    .Imageandname {
        width: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .Timeandnumber {
            width: 20%;
        }
}