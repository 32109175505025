.SignUp {
    width: 100%;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SignupLabel{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}