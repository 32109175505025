/*About City styles*/

.AboutCity{
    width: 100%;
    height: auto;
    min-height: 80vh;
    max-width: 1200px;
    background-color: #ffffff;
    border-radius:  25px 25px 0 0 ;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.Map{
    width: 90%;
    height: 300px;
    max-width: 1200px;
    background-color: #ffffff;
    border-radius:  25px 25px 0 0 ;
}
.Heading{
    width : 90%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.Description{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
}

.Photos {
    width: 100%;
    height: 90vh;
    max-height: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    margin-bottom: 3rem;
    padding: 2rem;
}

.ImageContainer{
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

}

.ImageContainer>img {
    width: 40%;
    height: 150px;
    margin-bottom: 1rem;
    object-fit: cover;
}

@media screen and (min-width: 768px) {
    .ImageContainer {
            width: 100%;
            height: 100%;
            margin-left: 100px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
        }
    .ImageContainer>img{
        width: 20%;
        height: 200px;
        margin-bottom: 1rem;
        display: block;
        margin-right: 20px;
    }
}

