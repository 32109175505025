.ProfilePicture{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow:  0 0 3px 0 rgba(0,0,0,0.5);
    cursor: pointer;
    margin: 0 auto;
    margin-top: 30px;
    position: relative;
}
.ProfilePicture:hover{
    opacity: 0.5;
    box-shadow:  0 0 5px 0 rgba(0,0,0,0.5);
}

.ProfilePicture img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.UploadButton {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;
    box-shadow:  0 0 3px 0 rgba(0,0,0,0.5);
}
.UploadButton:hover{
    background-color: #000;
    color: #fff;
}
.UploadButton:disabled{
    background-color: #ccc;
    color: #000;
    cursor: not-allowed;
}
.UploadButton:disabled:hover{
    background-color: #ccc;
    color: #000;
}
.UploadButton:disabled:active{
    background-color: #ccc;
    color: #000;
}

.SelectImage{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

}
.SelectImage p {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-shadow:  0 0 3px rgba(0,0,0,0.8);
    cursor: pointer;
}