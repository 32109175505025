/*Header Text styles*/

.HeaderText{
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
    margin:  0 5%;
    padding-top: 20px;
    padding-bottom: 10px;
    text-align: center;
    
}
@media screen and (min-width: 768px) {
    .HeaderText{
        font-size: 2rem;
    }
}