.Heading{
    text-align: center;
    margin-top: 4.5rem;
    margin-bottom: 1rem;
    color: #ffffff;
    font-size: 2rem;
    font-weight: 600;
}
.FlexContainer{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 3rem;
    justify-content: space-evenly;
}

.FlexContainer>img {
    /* background-color: #ffffff; */
    width: 10%;
    height: 5rem;
    object-fit: cover;
}


@media screen and (max-width: 468px) {
    .FlexContainer>img {
            width: 35%;
            height: 3.5rem;
            margin-top: .5rem;
        }
}