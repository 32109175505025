/*Navigation Text styles*/
.BottomNavigationText{
    font-size: .8rem;
    font-weight: 600;
    color: #0E0E0F;
    margin:  0 15px;
    display: none;
    
}


@media screen and (min-width: 768px) {
    .BottomNavigationText{
        font-size: 1.2rem;
        font-weight: 600;
        color: #0E0E0F;
        margin: 0 15px;
        display: block;
    }
}