.Container{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.Container>Link>img{
    width: 7px;
    height: 11px;
    margin-left: 2rem;
}

.Container>p{
    display: flex;
    width: 85%;
    justify-content: center;
}