/*OrganizationCard styles*/

.OrganizationCard{
    width: 160px;
    height: 170px;
    background-color: white;
    border-radius: 15px;
    box-shadow:  0 0 3px 0 rgba(0,0,0,0.5);
    cursor: pointer;
    margin-bottom:  25px;
    margin-right: 5px;
    padding: 5px;
}
.OrganizationCard:hover{
    transform: scale(1.1);
    box-shadow:  0 0 5px 0 rgba(0,0,0,0.5);
}
.OrganizationCard h2{
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
    padding: 0 5px;
}
.OrganizationCard p{
    font-size: 0.8rem;
    font-weight: 600;
    text-align: center;
    margin: 0;
    padding: 5px;
    color: #4A4B4B;
}
.OrganizationCardImage{
    width: 80%;
    height: 50%;
    margin: 0 auto;
    padding: 5px

}
.OrganizationCardImage img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
}
@media screen and (min-width: 768px) {
    .OrganizationCard{
        margin: 0 10px;
        margin-bottom: 40px;
        width: 200px;
        height: 160px;
    }
}