/*About City styles*/

.AboutCity {
    width: 100%;
    height: 100vh;
    max-width: 1200px;
    background-color: #ffffff;
    border-radius: 25px 25px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.Map {
    width: 90%;
    height: 300px;
    max-width: 1200px;
    background-color: #ffffff;
    border-radius: 25px 25px 0 0;
}

.Rating {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

/* .AboutContentContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.AboutContentContainer>div:nth-child(2){
    width: 90%;
    float: left;
} */

.Photos {
    width: 100%;
    height: 90vh;
    max-height: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    margin-bottom: 3rem;
    padding: 2rem;
}

.Container{
 display: block;
 /* align-items: center; */
 padding: 1rem;
 margin: 1rem;
 margin-left: 5rem;
 /* height: 10vh; */
 width: 80%;
}
.Container>img{
    width: 90px;
    /* height: 90px; */
    object-fit: contain;
}
.Stack{
    display: flex;
    flex-direction: column;
    gap: 2;
    width: 70%;
    margin-left: .5rem;
}
 .Stack>p{
    /* white-space: nowrap; */
    font-size: 1.2rem;
    font-weight: 800;
 }

 .Stack>p:nth-child(2){
    font-size: 1rem;
    font-weight: 500;
    color: rgba(14, 14, 15, 0.75);
    margin-top: -1rem;
 }

 .Image{
    width: 450px;
    height: 200px;
    margin-bottom: .75rem;
 }

 .Link{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    margin-bottom: 2rem;
    margin-top: 1rem;
 }
 .ContactLink {
    display: flex;
    flex-direction: column;
 }

 .Link>a{
    text-decoration: none;
 }


 .OrganizationDescription {
    margin-left: 3rem;
    margin-right: 3rem;
 }
 @media screen and (max-width: 468px) {
    .Container {
        display: flex;
        align-items: center;
        padding: 1rem;
        margin: 1rem;
        height: 5vh;
    }
        .AboutCity {
            height: 100vh;
            background-color: #ffffff;
            border-radius: 25px 25px 0 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

    .Container>img {
        width: 45px;
        height: 45px;
    }
    .Stack{
        width: 70%;
    }
    .Stack>p {
    font-size: 18px;
    font-weight: 700;
    }

    .Stack>p:nth-child(2) {
        font-size: 12px;
        font-weight: 400;
        color: rgba(14, 14, 15, 0.75);
        margin-top: -1rem;
    }

        .Photos {
            width: 90%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 3rem;
        }
 }