
/*splash screen styles*/
.Splash {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: row;
    justify-content: center;
    align-items: center;
    color: #fff;
}

/*Home screen styles*/
.Home {
    width: 100%;
}
.NavigationButton {
    width: 50px;
    height: 50px;
    background-color: #fff;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    cursor: pointer;
}

/* Nav styles */

.NavContainer {
    width: 95%;
    padding: 2rem;
    height: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
}
.NavContainer:hover{
    box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
}

.NavItems {
    display: flex;
    flex-direction: row;
justify-content: center;
    align-items: center;
}

.NavItems>img {
    /* width: 10rem; */
    height: 2rem;
}

.NavItem {
    margin-right: 2rem;
    font-size: 30px;
    font-weight: 500;
    color: #fff;
}


.Ptag {
    margin-left: 2rem;
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    display: flex;
}

.NavItemf {
    margin-right: 2rem;
    font-size: 1rem;
    font-weight: 500;
    color: '#fff !important';

}
.Notification{
    display: block;
    /* position: absolute;
    top: 10%;
    left:90%; */
    width: 2rem;
    min-width: 1rem;
    height: 2rem;
    min-height: 1rem;
    padding: .5rem;
    background-color: #fff;
    border-radius: 50%;
    color:#000;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .NavContainer {
        padding: .81rem;
        margin: auto;
        width: 90%;
    }
        .Notification {
            display: block;
            /* position: absolute;
        top: 10%;
        left:90%; */
            width: .2rem;
            height: .2rem;
            padding: 0rem;
            background-color: #fff;
            border-radius: 50%;
            color: #000;
            cursor: pointer;
            justify-content: center;
            align-items: center;
        }

    .NavItem,
    .NavItemf {
        margin-right: .5rem;
        font-size: .75rem;
        font-weight: 700;
    }

    .NavItems>img {
        height: 1rem;
    }
        .Ptag {
            margin-left: 0rem;
            font-size: .75rem;
            font-weight: 500;
            color: #fff;
            display: flex;
        }
    
      
}

