.Container {
    padding: .75rem;
    width: 85%;
    margin: auto;
    color: #fff;
}

.HeaderContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}


.HeaderContainer>p {
    display: flex;
    width: 85%;
    justify-content: flex-start;
}

