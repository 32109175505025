/*navigation Button Style */

.NavigationButton {
    width: 50px;
    height: 50px;
    background-color: #fff;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    cursor: pointer;
    
}