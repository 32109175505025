/*Organizations styles*/

.Organizations{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    /* padding-top: 30px; */
    margin: 0 auto;
}
.OrganizationsContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
}

@media screen and (min-width: 768px) {
    .Organizations{
        justify-content: flex-start;
    }
}
    