.EmailVerification {
     display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    color: #fff;
}

.VerificationButton{
    width: 60%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 20px 0px;
    font-size: 18px;
}





@media screen and (min-width: 768px) {
    .EmailVerification{
        margin-bottom: 0;
        height: 100vh;
    }
   .VerificationButton{
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 20px 0px;
}
}