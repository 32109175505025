.Container {
    padding: .75rem;
    width: 95%;
    margin: auto;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.HeaderContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}


.HeaderContainer>p {
    display: flex;
    width: 85%;
    justify-content: flex-start;
}

.HelpContainer{
    width: 85%;
    background: rgba(14, 14, 15, 0.1);
    border-radius: 10px;
    height: 3rem;
    display: flex;
    margin-bottom: 1rem;
    padding: .5rem;
    align-items: center;
}

.HelpContainer img{
    display: block;
    margin-left: .5rem;
    margin-right: .5rem;
}