/* .Home {
    width: 100%;
    background-color: #1F4490;
    background-image: url("../../assets/banner.png");
}

.CommunityHeader{
    width: 100%;
    display: flex;
    justify-content: space-between;
} */

.Container {

    margin: auto;
    background-color: #1F4490;
        background-image: url("../../assets/banner.png");
}

.HeaderContainer {
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: .5rem;
}


.HeaderContainer>p {
    
    text-align: center;
    color: #fff;
    font-size: 2.5rem;
    font-weight: 700;
}

.AboutCity {
    width: 100%;
    height: 70vh;
    max-width: 1200px;
    background-color: #ffffff;
    border-radius: 25px 25px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: auto;
    margin-top: 2rem;
}

.Photos {
  width: 100%;
  height: auto;
max-width: 1200px;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 1px;
}

/* @media screen and (max-width: 768px) {
    .Container{
        padding: 0;
    }
} */

