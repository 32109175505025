.NotificationSetting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  width: 80%;
    max-width: 400px;
    font-size: 1rem;
}
.NotificationSetting:hover {
  background-color: rgb(75, 96, 129);
}
.NotificationSetting p {
  margin: 0;
  font-size: 1.2rem;
}
