.CityHeader{
    width: 100%;
    height: 30vh;
    /* background-color: #1F4490; */
    max-width:  1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.CityHeader h1{
    font-size: 2.0rem;
    font-weight: 600;
    background: #fff;
        padding: 1rem;
        border-radius: 16px;
        color: #333;
    margin-bottom: 10px;
    text-shadow:  0 0 3px rgba(0,0,0,0.5);
}

.CityHeader h2{
    font-size: 1.2rem;
    font-weight: 400;
    
    margin-bottom: 10px;
    text-shadow:  0 0 3px rgba(0,0,0,0.5);
}