/*Rating Container styles*/

.RatingContainer{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-top:  1px solid #e5e5e5;
}