/*Account Styles*/
.Account{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AditionalButton{
    top: 40px !important;
    font-size: 1.0rem !important;
}

.AditionalButton:hover{
    background-color: #fff !important;
    color: #000 !important;

}

@media screen and (max-width: 768px){
    .AditionalButton{
        padding: 0px 5px !important;
        top: 25px !important;
    }
    
}
    