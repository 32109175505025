.LogoutButton {
    padding: 5px 32px;
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;
    box-shadow:  0 0 3px 0 rgba(0,0,0,0.5);
    margin-bottom: 50px;
}
.LogoutButton:hover{
    background-color: red;
    color: #fff;
}