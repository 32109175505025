/*Login styles */

.Login {
    width: 100%;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SignupLabel{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.PasswordToggleContainer{
    height: 45px;
    margin-left: 20rem;
    margin-top: 4rem;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    cursor: pointer;
    position: absolute;
}

@media screen and (max-width: 768px) {
    .PasswordToggleContainer {
        margin-left: 20rem;
    }
}

@media screen and (max-width: 468px) {
    .PasswordToggleContainer{
        margin-left: 15rem;
    }
}
@media screen and (max-width: 368px) {
    .PasswordToggleContainer {
        margin-left: 11rem;
    }
}