/*Cities styles*/
.Cities{
    position: sticky;
    top: 0;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 1200px;
    width: 100%;
    height: auto;
    min-height: 100vh;
    border-radius:  25px 25px 0 0 ;
    background-color: #ffffff;
}
.CitiesContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}