.ChangePassword {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #fff;
}
.Form {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

@media screen and (min-width: 768px) {
    .ChangePassword {
        margin-bottom: 0;
        height: 100vh;
    }
    .Form {
        margin-top: 20px;
        box-shadow:   0 0 3px 0 rgba(255,255,255,0.5);
        padding: 100px 0;
    }
}